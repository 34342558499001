
@import '~noty/src/noty.scss';
@import '~noty/src/themes/mint.scss';



.noty_theme__mint{
    margin-top: 30px;
    border-radius: 50px !important;
    padding-left: 8px !important;
}