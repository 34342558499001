.slick-bg{
    box-shadow: 20px 20px 50px white;
}

.slick-prev:before, .slick-next:before {
    font-family: monospace;
    font-size: 41px;
    line-height: 0;
    opacity: .9;
    display: flex;
    justify-content: center;
    color: #6B6B6B;
}

.slick-prev {
    left: 3px;
}
.slick-next {
    right: 5px;
}
/* .slick-list { 
    padding: 0px !important;
} */

.slick-track {
    /* width: 100% !important; */
    padding: 0px !important;
    /* margin-left: 0px !important;
    margin-right: 0px !important; */
}

.slick-dots {
    bottom: -25px !important;
}


.slick-dots li button:before {
    font-family: 'slick';
    font-size: 10px !important;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #E9C95D !important;;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .slick-dots li button:hover, .slick-dots li button:focus {
    outline: none;
    color: #E9C95D !important;;
  }